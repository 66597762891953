import { useNavigate } from "@solidjs/router";
import { createEffect, type ParentComponent } from "solid-js";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import { BackgroundBlobLeft } from "~/domains/marketing/components/BackgroundBlobLeft";
import { BackgroundBlobRight } from "~/domains/marketing/components/BackgroundBlobRight";
import { LandingPageNavbar } from "~/domains/marketing/components/LandingPageNavbar";
import { useWire } from "~/wire";

const Auth: ParentComponent = (props) => {
  const wire = useWire();
  const navigate = useNavigate();

  createEffect(() => {
    if (!wire.services.identity.snapshot.context.identity.isGuest) {
      navigate("/");
    }
  });
  return (
    <div class="relative overflow-hidden h-screen w-screen flex flex-col">
      <BackgroundBlobLeft />
      <BackgroundBlobRight />

      <LandingPageNavbar class="text-black dark:text-white dark:bg-transparent static" />
      <div class="p-10 sm:pt-20">
        <div class="mx-auto max-w-lg sm:border border-violet-950/5 dark:border-indigo-950 dark:bg-indigo-700/5 dark:text-white rounded sm:px-10 sm:py-5">
          {props.children}
        </div>
      </div>
    </div>
  );
};
export default Auth;
